import React, { useEffect, useRef } from "react";
import * as S from "./LightBox.module.sass";
import { useDispatch, useSelector } from "react-redux";
import {
  closeLightBox,
  selectGalleryIndex,
  selectLightBox,
  updateGalleryIndex,
} from "../../../../redux/dataSlice";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import classNames from "classnames";

type PropsArrow = {
  onClick?: React.MouseEventHandler;
};

export const ArrowNext2 = ({ onClick }: PropsArrow) => {
  return <div className={S.Next} onClick={onClick} />;
};

export const ArrowPrev2 = ({ onClick }: PropsArrow) => {
  return <div className={S.Prev} onClick={onClick} />;
};

const LightBox = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const ligthBox = useSelector(selectLightBox);
  const galleryIndex = useSelector(selectGalleryIndex);
  // console.log("index=", index);

  useEffect(() => {
    //@ts-ignore
    ref.current?.slickGoTo(galleryIndex);
    // console.log("ligthBox updated", ligthBox, ref.current);
  }, [ligthBox]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: index,
    nextArrow: <ArrowNext2 />,
    prevArrow: <ArrowPrev2 />,
    //@ts-ignore
    afterChange: (index) => {
      // console.log("afterChange", index);
      dispatch(updateGalleryIndex({ index }));
    },
    //@ts-ignore
    onInit: () => {
      const allSlides = document.querySelectorAll(".lightbox-slide");
      const captionContainer = document.querySelector(".caption-container");
      // @ts-ignore
      captionContainer.innerText = allSlides[0].getAttribute("data-caption");
    },
    //@ts-ignore
    beforeChange: (current, next) => {
      const allSlides = document.querySelectorAll(".lightbox-slide");
      const captionContainer = document.querySelector(".caption-container");
      // @ts-ignore
      captionContainer.innerText = allSlides[next].getAttribute("data-caption");
    },
  };

  const imageStyle: React.CSSProperties | undefined = {
    width: `100%`,
    height: `0`,
    paddingTop: `56.25%`,
    position: `relative`,
  };

  const imgStyle: React.CSSProperties | undefined = {
    position: `absolute`,
    top: `0`,
    bottom: `0`,
    left: `0`,
    right: `0`,
  };

  const click = () => {
    // @ts-ignore
    dispatch(closeLightBox());
  };
  return (
    <div className={S.LightBox}>
      <div className={S.SlideshowContainer}>
        <div className={S.SContainer}>
          <Slider ref={ref} {...settings}>
            <div
              className={classNames("lightbox-slide", S.Slide)}
              data-caption="The Residences at Lakeview Village Front Facade"
            >
              <StaticImage
                alt="The Residences at Lakeview Village Front Facade"
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-1.jpg`}
              />
            </div>
            <div
              className={classNames("lightbox-slide", S.Slide)}
              data-caption="Kitchen In Chardonnay Colour Scheme"
            >
              <StaticImage
                alt="Kitchen In Chardonnay Colour Scheme"
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-2.jpg`}
              />
            </div>
            <div
              className={classNames("lightbox-slide", S.Slide)}
              data-caption="Kitchen In Pinot Colour Scheme"
            >
              <StaticImage
                alt="Kitchen In Pinot Colour Scheme"
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-3.jpg`}
              />
            </div>
            <div
              className={classNames("lightbox-slide", S.Slide)}
              data-caption="Bathroom in Chardonnay Colour Scheme"
            >
              <StaticImage
                alt="Bathroom in Chardonnay Colour Scheme"
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-4.jpg`}
              />
            </div>
            <div
              className={classNames("lightbox-slide", S.Slide)}
              data-caption="Bathroom in Pinot Colour Scheme"
            >
              <StaticImage
                alt="Bathroom in Pinot Colour Scheme"
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-5.jpg`}
              />
            </div>
            <div
              className={classNames("lightbox-slide", S.Slide)}
              data-caption="Rooftop Lounge"
            >
              <StaticImage
                alt="Rooftop Lounge"
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-6.jpg`}
              />
            </div>
            <div
              className={classNames("lightbox-slide", S.Slide)}
              data-caption="Balcony"
            >
              <StaticImage
                alt="Balcony"
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-7.jpg`}
              />
            </div>
          </Slider>
          <div className={S.Close} onClick={click} />
        </div>
      </div>

      <div className={classNames("caption-container", S.CaptionContainer)} />
    </div>
  );
};

export default LightBox;
