import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import * as S from "./Gallery.module.sass";
import classNames from "classnames";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import { useDispatch, useSelector } from "react-redux";
import {
  openLightBox,
  selectGalleryIndex,
  selectLightBox,
  updateGalleryIndex,
} from "../../../redux/dataSlice";

type Props = {
  className?: string;
};

type PropsArrow = {
  onClick?: React.MouseEventHandler;
};

export const ArrowNext = ({ onClick }: PropsArrow) => {
  return <div className={S.Next} onClick={onClick} />;
};

export const ArrowPrev = ({ onClick }: PropsArrow) => {
  return <div className={S.Prev} onClick={onClick} />;
};

const Gallery = ({ className }: Props) => {
  const dispatch = useDispatch();
  const ligthBox = useSelector(selectLightBox);
  const galleryIndex = useSelector(selectGalleryIndex);

  const ref = useRef(null);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    //@ts-ignore
    afterChange: (index) => {
      // console.log("afterChange", index);
      dispatch(updateGalleryIndex({ index }));
    },
  };

  const imageStyle: React.CSSProperties | undefined = {
    width: `100%`,
    height: `0`,
    paddingTop: `56.25%`,
    position: `relative`,
  };

  const imgStyle: React.CSSProperties | undefined = {
    position: `absolute`,
    top: `0`,
    bottom: `0`,
    left: `0`,
    right: `0`,
  };

  useEffect(() => {
    //@ts-ignore
    ref.current?.slickGoTo(galleryIndex);
    // console.log("ligthBox updated", ligthBox, ref.current);
  }, [ligthBox]);

  useEffect(() => {
    /**
     * Gallery
     * mask
     */
    const galleryMaskTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: "#gallery-main",
        start: "top 85%",
        end: "bottom 100%",
        toggleActions: "play none none none",
        scrub: true,
      },
    });
    galleryMaskTimeline.fromTo(
      "#gallery-main",
      {
        clipPath: `polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)`,
      },
      {
        clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
      }
    );
  }, []);

  const clickImage = () => {
    console.log("index");
    // @ts-ignore
    dispatch(openLightBox());
  };

  return (
    <div className={classNames([className, S.Gallery])}>
      <div className={S.Inner}>
        <div className={S.Title}>
          <h1>Gallery</h1>
        </div>
        <div id={`gallery-main`} className={S.Main}>
          <Slider ref={ref} {...settings}>
            <div className={S.Slide} onClick={() => clickImage()}>
              <StaticImage
                alt={``}
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-1.jpg`}
              />
            </div>
            <div className={S.Slide} onClick={() => clickImage()}>
              <StaticImage
                onClick={() => clickImage()}
                alt={``}
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-2.jpg`}
              />
            </div>
            <div className={S.Slide} onClick={() => clickImage()}>
              <StaticImage
                alt={``}
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-3.jpg`}
              />
            </div>
            <div className={S.Slide} onClick={() => clickImage()}>
              <StaticImage
                alt={``}
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-4.jpg`}
              />
            </div>
            <div className={S.Slide} onClick={() => clickImage()}>
              <StaticImage
                alt={``}
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-5.jpg`}
              />
            </div>
            <div className={S.Slide} onClick={() => clickImage()}>
              <StaticImage
                alt={``}
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-6.jpg`}
              />
            </div>
            <div className={S.Slide} onClick={() => clickImage()}>
              <StaticImage
                alt={``}
                style={imageStyle}
                imgStyle={imgStyle}
                objectFit={`cover`}
                src={`../../../gallery/image-7.jpg`}
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
